import cn from 'clsx'
import React from 'react'
import { checkIsStringAHtml } from 'utils/String'

import DescriptionModal from './DescriptionModal'
import Heading from './Heading'

/**
 * Description component returns text with line breaks.
 *
 * @component
 * @param {Object} props - The props object.
 * @param {string} props.text - The text to display with line breaks.
 * @param {boolean} props.openInModal - Determines whether to show description on a modal. Also you should not be using useExpandContract prop together with openInModal prop.
 * @param {string} props.className - Additional className for the paragraph tag.
 * @param {...Object} rest - Other props to pass to the paragraph tag.
 * @returns {React.Element} A paragraph element with text broken by line breaks.
 */
const Description = ({
  title = '',
  text = '',
  className = '',
  parentClassName,
  threshold = 200,
  useExpandContract = false,
  openInModal = false,
  ...props
}) => {
  const [isExpanded, setIsExpanded] = React.useState(!useExpandContract)

  const isDescriptionAHtml = checkIsStringAHtml(text)

  const renderedText = isDescriptionAHtml ? (
    <p
      className={className}
      {...props}
      dangerouslySetInnerHTML={{
        __html: text.slice(0, !isExpanded && text.length > threshold ? threshold : text.length),
      }}
    />
  ) : (
    <p className={className} {...props}>
      {text
        ?.slice(0, !isExpanded && text.length > threshold ? threshold : text.length)
        .split('\n')
        .map((value, index) => (
          <React.Fragment key={index}>
            {value}
            <br />
          </React.Fragment>
        ))}
    </p>
  )

  return (
    <>
      {openInModal ? (
        <DescriptionModal
          heading={title}
          description={renderedText}
          parentClassName={parentClassName}
        />
      ) : (
        <div className={parentClassName}>
          {title && <Heading preTitle={title} />}

          {renderedText}

          <div className={cn('flex justify-end', useExpandContract ? 'block' : 'hidden')}>
            <button className='text-secondary underline' onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? 'Read less' : 'Read more'}
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default Description
